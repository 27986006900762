<template>
  <div class="transactions">
    <b-card no-body>
      <b-card-header>
        <div class="balance">
          Balance:
          <span>{{ isoCode === 'BRA' ? 'R$' : 'Mex$' }}{{ balance }}</span>
        </div>
      </b-card-header>
      <div class="title">
        Transaction List
      </div>
      <b-row class="transaction-filter">
        <b-col md="6">
          <label
            class="mr-sm-1"
            for="inline-form-custom-select-pref"
          >Transaction Type:</label>
          <v-select
            v-model="transactionType"
            :options="transactionTypeOption"
            class="invoice-filter-select mb-1 mr-sm-1"
            placeholder="Transaction Type"
            label="label"
            multiple
            @input="transactionTypeChange"
          />
        </b-col>
        <b-col md="6">
          <label
            class="mr-sm-1"
            for="inline-form-custom-select-pref"
          >Status:</label>
          <v-select
            v-model="transactionStatus"
            :options="transactionStatusOption"
            class="invoice-filter-select mb-1 mr-sm-1"
            placeholder="Status"
            label="label"
            multiple
            @input="transactionStatusChange"
          />
        </b-col>
      </b-row>
      <b-table
        ref="kyc-records-table"
        :items="transactionList"
        :fields="transactionFields"
        :bordered="bordered"
        :small="small"
        responsive
        class="transaction-table"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Id -->
        <template #cell(transaction_id)="data">
          <b-link
            v-if="data.item.transaction_type === 'DEPOSIT'"
            :to="{
              path: '/deposit/deposit-detail',
              query: { id: data.value, timezone: getQueryString('timezone') },
            }"
            class="font-weight-bold"
            target="_blank"
          >
            {{ data.value }}
          </b-link>
          <b-link
            v-if="data.item.transaction_type === 'WITHDRAW'"
            :to="{
              path: '/withdraw/withdraw-detail',
              query: { id: data.value, timezone: getQueryString('timezone') },
            }"
            class="font-weight-bold"
            target="_blank"
          >
            {{ data.value }}
          </b-link>
        </template>
        <!-- Column: business_type -->
        <template #cell(business_type)="data">
          {{ getBusinessTypeText(data.value) }}
        </template>
        <!-- Column: created_time -->
        <template #cell(created_time)="data">
          {{ updateDate(data.value, getQueryString('timezone')) }}
        </template>
        <!-- Column: updated_time -->
        <template #cell(updated_time)="data">
          {{ updateDate(data.value, getQueryString('timezone')) }}
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  getCurrentInstance,
  watch,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BCardHeader,
  BTable,
  BPagination,
  BRow,
  BCol,
  BLink,
} from 'bootstrap-vue'
import { updateDate, getQueryString } from '@/libs/utils'
import store from '@/store'
import userCenterStoreModule from '../userCenterStoreModule'

export default defineComponent({
  components: {
    BCard,
    BCardHeader,
    BTable,
    BPagination,
    BRow,
    BCol,
    BLink,
    vSelect,
  },
  setup() {
    const USERCENTER_APP_STORE_MODULE_NAME = 'userCenter'

    // Register module
    if (!store.hasModule(USERCENTER_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        USERCENTER_APP_STORE_MODULE_NAME,
        userCenterStoreModule,
      )
    }
    const { proxy } = getCurrentInstance()
    const { $route } = proxy
    // Use toast
    const toast = useToast()
    const balance = ref(null)
    const isoCode = ref(null)
    const transactionList = ref([])
    const transactionFields = ref([
      { key: 'business_type', label: 'transaction type' },
      { key: 'transaction_id', label: 'transaction id' },
      { key: 'transaction_status_text', label: 'status' },
      { key: 'currency', label: 'currency' },
      { key: 'amount', label: 'amount' },
      { key: 'created_time', label: 'create time' },
      { key: 'updated_time', label: 'update time' },
    ])
    const bordered = ref(true)
    const small = ref(true)
    const perPage = ref(10)
    const currentPage = ref(1)
    const total = ref(0)

    const dataMeta = computed(() => ({
      from: currentPage.value * perPage.value - (perPage.value - 1),
      to:
        total.value - currentPage.value * perPage.value > 0
          ? currentPage.value * perPage.value
          : total.value,
      of: total.value,
    }))
    // 3 交易；10 充值；11 提现；8 转账；
    const transactionTypeOption = ref([
      // { label: 'All', value: '' },
      { label: 'Withdraw', value: '11' },
      { label: 'Purchase', value: '3' },
      // { label: 'Transfer', value: '8' },
      { label: 'Deposit', value: '10' },
    ])
    // 0 创建；1 成功；2 失败；3 处理中
    const transactionStatusOption = ref([
      // { label: 'All', value: '' },
      { label: 'Success', value: '1' },
      { label: 'Processing', value: '3' },
      { label: 'Pending', value: '0' },
      { label: 'Rejected', value: '2' },
      { label: 'Refunded', value: '4' },
    ])
    const transactionType = ref([])
    const transactionStatus = ref([])
    const fetchTransactionList = () => {
      const { id } = $route.query
      const params = {
        user_id: id,
        page_no: currentPage.value,
        page_size: perPage.value,
        business_types: [],
        transaction_status: [],
      }
      params.business_types = transactionType.value.map(value => Number(value.value))
      params.transaction_status = transactionStatus.value.map(value => Number(value.value))
      proxy.$showLoading()
      store
        .dispatch('userCenter/fetchTransactionList', params)
        .then(response => {
          proxy.$hideLoading()
          if (response.data.code === 200) {
            const { list, pagination, user_info } = response.data.data
            transactionList.value = list
            total.value = pagination.total_count
            isoCode.value = user_info.iso_code
            balance.value = user_info.balance

            proxy.$emit('userInfo', {
              username: user_info.name,
              userid: user_info.user_id,
            })
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    }
    onMounted(() => {
      currentPage.value = 1
      fetchTransactionList()
    })
    const transactionTypeChange = () => {
      currentPage.value = 1
      fetchTransactionList()
    }
    const transactionStatusChange = () => {
      currentPage.value = 1
      fetchTransactionList()
    }
    const getBusinessTypeText = item => {
      const filterItem = transactionTypeOption.value.filter(
        option => Number(option.value) === item,
      )
      return filterItem[0].label
    }
    watch([perPage, currentPage], () => {
      fetchTransactionList()
    })
    return {
      isoCode,
      balance,
      transactionList,
      transactionFields,
      bordered,
      small,
      perPage,
      currentPage,
      total,
      dataMeta,
      updateDate,
      getQueryString,
      transactionTypeOption,
      transactionStatusOption,
      transactionType,
      transactionStatus,
      transactionTypeChange,
      transactionStatusChange,
      getBusinessTypeText,
    }
  },
})
</script>
<style lang="scss" scope>
.per-page-selector {
  width: 90px;
}

.transactions {
  .balance {
    font-weight: 500;
    font-size: 16px;
    span {
      font-size: 28px;
      color: #7b6df2;
    }
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    padding: 0 0 10px 20px;
  }
  .transaction-filter,
  .transaction-table {
    padding: 0 20px;
  }
}
.invoice-filter-select {
  .vs__selected-options {
    flex-wrap: nowrap;
  }

  // ::v-deep .vs__selected {
  //   width: 100px;
  // }
}
</style>
