<template>
  <div class="user-detail">
    <b-card
      class="mb-1"
      no-body
    >
      <div class="user-top">
        <div class="top-left">
          User Details
        </div>
        <div class="top-divide" />
        <div class="top-right">
          <h3>{{ u_name }}</h3>
          <p>
            USER ID: <span>{{ u_id }}</span>
          </p>
        </div>
      </div>
    </b-card>
    <div class="user-tabs">
      <div
        v-for="(item, index) in userTabs"
        :key="index"
        :class="['user-tab', selectedIndex === index ? 'active' : '']"
        @click="selectedIndex = index"
      >
        <img
          :src="selectedIndex === index ? item.selected : item.select"
          alt="image"
        >
        {{ item.name }}
      </div>
    </div>
    <base-info
      v-if="selectedIndex === 0"
      @userInfo="getUserInfo"
    />
    <transactions
      v-if="selectedIndex === 1"
      @userInfo="getUserInfo"
    />
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import { BCard } from 'bootstrap-vue'
import BaseInfo from './components/BaseInfo.vue'
import Transactions from './components/Transactions.vue'

export default defineComponent({
  components: {
    BaseInfo,
    Transactions,

    BCard,
  },
  setup() {
    const state = reactive({
      userTabs: [
        {
          name: 'Basic Info',
          select: require('@/assets/images/usercenter/baseinfo.png'),
          selected: require('@/assets/images/usercenter/baseinfo_selected.png'),
        },
        {
          name: 'Transactions',
          select: require('@/assets/images/usercenter/transaction.png'),
          selected: require('@/assets/images/usercenter/transaction_selected.png'),
        },
      ],
      selectedIndex: 0,
      u_name: '',
      u_id: '',
    })
    const {
      userTabs, selectedIndex, u_name, u_id,
    } = toRefs(state)
    const getUserInfo = value => {
      state.u_name = value.username
      state.u_id = value.userid
    }
    return {
      userTabs,
      selectedIndex,
      u_name,
      u_id,

      getUserInfo,
    }
  },
})
</script>

<style lang="scss">
.user-detail {
  width: 80%;
  padding: 4rem 0;
  margin: 0 auto;
  min-height: 400px;
  .user-top {
    display: flex;
    align-items: center;
    .top-left {
      color: #333;
      font-size: 26px;
      margin-left: 25px;
    }
    .top-divide {
      width: 2px;
      height: 50px;
      background: #dfdfdf;
      margin: 0 40px;
    }
    .top-right {
      font-size: 12px;
      line-height: 25px;
      font-weight: bold;
      h3 {
        color: #7b6ce8;
        font-size: 20px;
        margin-top: 12px;
      }
      p {
        font-weight: normal;
        span {
          color: #7b6ce8;
          font-weight: bold;
        }
      }
    }
  }
  .user-tabs {
    background-color: #7b6df2;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: #d4cefc;
    display: flex;
    padding: 0 20px;
    align-items: center;
    .user-tab {
      border-right: 2px solid #d4cefc;
      margin: 15px 0;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      &:nth-of-type(1) {
        padding-right: 20px;
      }
      &:nth-of-type(2) {
        padding-left: 20px;
        border-right: none;
      }
      &.active {
        color: #fff;
      }
    }
  }
}
</style>
