<template>
  <div class="base-info">
    <b-row>
      <b-col
        cols="24"
        md="12"
      >
        <b-card title="Basic Info">
          <b-col cols="24 pt-0">
            <hr>
          </b-col>
          <div class="basic-content">
            <div class="basic-content-c">
              <b-card-text>User ID: {{ userDetail.uuid }}</b-card-text>
              <b-card-text>Country:
                {{
                  userDetail.isoCode === 'MEX' ? 'Mexico' : 'Brazil'
                }}</b-card-text>
              <!-- <b-card-text>Nickname: </b-card-text>
              <b-card-text>Avatar:</b-card-text> -->
              <b-card-text>Create time: {{ createTime }}</b-card-text>
              <b-card-text>Balance: {{ userDetail.amount }}</b-card-text>
              <b-card-text>Email: {{ userDetail.email }}</b-card-text>
              <b-card-text>Source: {{ userDetail.source }}</b-card-text>

            </div>
            <div class="basic-content-c">
              <b-card-text>Name: {{ userDetail.name }}</b-card-text>
              <b-card-text>Phone number: {{ phoneNumber }}</b-card-text>
              <b-card-text>Document type: {{ documentType }}</b-card-text>
              <b-card-text>Document ID: {{ documentId }}</b-card-text>
              <b-card-text style="display: flex">
                User Status: {{ showStatus[userDetail.userStatus] }}
                <!-- :checked="userStatusChecked" -->
                <b-form-checkbox
                  v-model="userStatusChecked"
                  v-hasBtn="`user_list`"
                  class="ml-2"
                  switch
                  inline
                  @change="userStatusCheckedChange"
                />
              </b-card-text>
              <b-card-text>Post Code: {{ userDetail.cep }}</b-card-text>

            </div>
            <div class="basic-content-d">
              <b-card-text>Salary Bracket: {{ userDetail.salary }}</b-card-text>
              <b-card-text>Address line 1: {{ userDetail.street }}</b-card-text>
              <b-card-text>Address line 2: {{ userDetail.city }}</b-card-text>
              <b-card-text>ISO Code: {{ userDetail.isoCode }}</b-card-text>
              <b-card-text>KYC Status:
                {{ formatKycStatustext(userKycData.kyc_status) }}</b-card-text>
              <!-- <b-card-text>Birthday: </b-card-text> -->
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="24"
        md="12"
      >
        <b-card title="Receiving accounts">
          <div>
            <!-- <h4>Bank account</h4> -->
            <b-tabs>
              <b-tab
                title="Bank account"
                active
              >
                <b-card-text>
                  <b-table
                    :items="bankAccountData"
                    :fields="
                      userDetail.isoCode === 'MEX'
                        ? bankAccountDataMEXFields
                        : bankAccountDataFields
                    "
                    :bordered="bordered"
                    :small="small"
                    responsive
                    class="receiving-table"
                    show-empty
                    empty-text="No matching records found"
                  />
                </b-card-text>
              </b-tab>
              <!-- <b-tab title="Picpay account">
                <b-card-text>
                  <b-table
                    :items="picAccountData"
                    :fields="picAccountDataFields"
                    :bordered="bordered"
                    :small="small"
                    responsive
                    class="receiving-table"
                    show-empty
                    empty-text="No matching records found"
                  />
                </b-card-text>
              </b-tab>
              <b-tab title="PIX account">
                <b-card-text>
                  <b-table
                    :items="pixAccountData"
                    :fields="pixAccountDataFields"
                    :bordered="bordered"
                    :small="small"
                    responsive
                    class="receiving-table"
                    show-empty
                    empty-text="No matching records found"
                  />
                </b-card-text>
              </b-tab>
              <b-tab title="Mercadopago account">
                <b-card-text>
                  <b-table
                    :items="mpAccountData"
                    :fields="mpAccountDataFields"
                    :bordered="bordered"
                    :small="small"
                    responsive
                    class="receiving-table"
                    show-empty
                    empty-text="No matching records found"
                  >
                    <template #row-details="row">
                      <b-card>
                        <b-row class="mb-2">
                          <b-col
                            md="3"
                            class="mb-1"
                          >
                            <strong>PIX type: </strong>{{ row.item.PIXType }}
                          </b-col>
                          <b-col
                            md="3"
                            class="mb-1"
                          >
                            <strong>PIX key: </strong>{{ row.item.PIXKey }}
                          </b-col>
                        </b-row>
                      </b-card>
                    </template>
                  </b-table>
                </b-card-text>
              </b-tab> -->
            </b-tabs>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="24"
        md="12"
      >
        <b-card
          v-hasBtn="`user_list`"
          title="KYC ID Upload"
        >
          <div
            v-if="!userKycData.id_document_photo"
            class="text-center bold"
          >
            No matching records found
          </div>
          <div
            v-if="userKycData.id_document_photo"
            class="kyc-id-wrap"
          >
            <div class="kyc-id-upload">
              <div class="kyc-id-box">
                <p>ID Document</p>
                <div class="img-box">
                  <img
                    :src="`${userKycData.domain}${userKycData.id_document_photo}`"
                    alt=""
                  >
                  <div
                    class="id-card-bg"
                    :style="{'background-color': formatStatusBg(userKycData.kyc_status)}"
                  />
                  <div class="id-card-text">
                    {{ formatKycStatustext(userKycData.kyc_status) }}
                  </div>
                </div>
              </div>
              <div class="kyc-id-box">
                <p>Selfie with ID</p>
                <div class="img-box">
                  <img
                    :src="`${userKycData.domain}${userKycData.id_selfie}`"
                    alt=""
                  >
                  <div
                    class="id-card-bg"
                    :style="{'background-color': formatStatusBg(userKycData.kyc_status)}"
                  />
                  <div class="id-card-text">
                    {{ formatKycStatustext(userKycData.kyc_status) }}
                  </div>
                </div>
              </div>
              <div class="kyc-id-box">
                <p>Proof of Address</p>
                <div class="img-box">
                  <img
                    v-if="userKycData.address_pic"
                    :src="`${userKycData.domain}${userKycData.address_pic}`"
                    alt=""
                  >
                  <div
                    class="id-card-bg"
                    :style="{'background-color': formatStatusBg(userKycData.address_status)}"
                  />
                  <div class="id-card-text">
                    {{ formatKycStatustext(userKycData.address_status) }}
                  </div>
                </div>
              </div>
              <div class="kyc-id-box">
                <p>Source of Funds</p>
                <div class="img-box">
                  <img
                    v-if="userKycData.fund_source_pic"
                    :src="`${userKycData.domain}${userKycData.fund_source_pic}`"
                    alt=""
                  >
                  <div
                    class="id-card-bg"
                    :style="{'background-color': formatStatusBg(userKycData.fund_source_status)}"
                  />
                  <div class="id-card-text">
                    {{ formatKycStatustext(userKycData.fund_source_status) }}
                  </div>
                </div>
              </div>
            </div>
            <b-button
              variant="primary"
              block
              class="kyc-id-check"
              @click="kycCheckFunc"
            >
              Check
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="24"
        md="12"
      >
        <b-card title="Record">
          <b-button
            v-hasBtn="`user_list`"
            variant="primary"
            class="record-note"
            @click="showNoteModal"
          >
            <feather-icon icon="PlusIcon" />
            Note
          </b-button>
          <b-table
            ref="kyc-records-table"
            :items="kycRecordsData"
            :fields="recordFields"
            :bordered="bordered"
            :small="small"
            responsive
            class="receiving-table"
            show-empty
            empty-text="No matching records found"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <!-- Column: created_at -->
            <template #cell(created_at)="data">
              {{ updateDate(data.value / 1000, getQueryString('timezone')) }}
            </template>
            <!-- Column: content -->
            <template #cell(content)="data">
              <p style="width: 350px">
                {{ data.value }}
              </p>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span
                  class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                  {{ dataMeta.of }} entries</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRecord"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col cols="12"
             md="6">
        <b-card title="Operation">
          <b-col cols="24 pt-0">
            <hr>
          </b-col>
          <b-form inline
                  class="userinfo-form">
            <label class="mr-sm-1 mb-1"
                   for="inline-form-custom-select-pref">Select status：</label>
            <v-select v-model="operationForm.selectStatus"
                      :options="selectStatusOption"
                      class="invoice-filter-select mb-1 mr-sm-1"
                      placeholder="All">
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
            <br />
            <label class="mr-sm-1 mb-1"
                   for="inline-form-custom-select-pref">Remark：</label>
            <b-form-input v-model="operationForm.remark"
                          class="d-inline-block mr-1 mb-1"
                          placeholder="" />
            <br />
            <label class="mr-sm-1 mb-1"
                   for="inline-form-custom-select-pref">Password：</label>
            <b-form-input v-model="operationForm.password"
                          class="d-inline-block mr-1 mb-1"
                          placeholder="" />
            <b-button variant="primary"
                      class="mb-1 mr-2">Submit</b-button>
          </b-form>
        </b-card>
      </b-col>
      <b-col cols="12"
             md="6">
        <b-card title="History">
          <b-col cols="24 pt-0">
            <hr>
          </b-col>
          <b-table :items="items"
                   :fields="fields"
                   responsive
                   class="history-table"
                   empty-text="No matching records found">
            <template #row-details="row">
              <b-card>
                <b-row class="mb-2">
                  <b-col md="8"
                         class="mb-1">
                    <strong>Status: </strong>{{ row.item.Status }}
                  </b-col>
                  <b-col md="8"
                         class="mb-1">
                    <strong>Date: </strong>{{ row.item.Date }}
                  </b-col>
                  <b-col md="8"
                         class="mb-1">
                    <strong>Remark: </strong>{{ row.item.Remark }}
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row> -->
    <b-modal
      ref="enter-note-below"
      title="Note"
      hide-footer
      :no-close-on-backdrop="true"
      centered
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Note"
                  rules="required"
                >
                  <label for="textarea-default">Enter note below</label>
                  <b-form-textarea
                    id="textarea-default"
                    v-model="enterNoteBelow"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter note here..."
                    rows="3"
                    max-length="300"
                    :formatter="enterNoteBelowChange"
                  />
                  <small class="textarea-counter-value float-right">
                    <span class="char-count">{{ enterNoteBelow.length }}</span>
                    / 300
                  </small>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="text-center"
            >
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="addNote"
              >
                Add
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- modal -->
    <b-modal
      ref="kyc-check"
      hide-footer
      modal-class="modal-primary"
      centered
      title="KYC Check"
      size="lg"
      :no-close-on-backdrop="true"
      @ok="handleKycCheckOk"
    >
      <kyc-check-component
        :user-id="userDetail.uuid"
        :name="userDetail.name"
        :document-type="documentType"
        :document-id="documentId"
        :street="userDetail.street"
        :city="userDetail.city"
        :salary="userDetail.salary"
        :id-document="userKycData.id_document_photo?`${userKycData.domain}${userKycData.id_document_photo}`:''"
        :id-selfie="userKycData.id_selfie?`${userKycData.domain}${userKycData.id_selfie}`:''"
        :address-pic="userKycData.address_pic?`${userKycData.domain}${userKycData.address_pic}`:''"
        :fund-source-pic="userKycData.fund_source_pic?`${userKycData.domain}${userKycData.fund_source_pic}`:''"
        :key-status="userKycData.kyc_status"
        :fund-source-status="userKycData.fund_source_status"
        :address-status="userKycData.address_status"
        @afterKycCheck="afterKycCheck"
      />
    </b-modal>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BTable,
  BForm,
  BFormInput,
  BButton,
  BTabs,
  BTab,
  BFormCheckbox,
  BFormTextarea,
  BFormGroup,
  BPagination,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  getQueryString,
  updateDate,
  formatCPF,
  formatCNPJ,
  formatPhoneNumber,
  formatDocumentId,
  formatAccountNumber,
  getDocumentType,
} from '@/libs/utils'
import kycCheckComponent from '../../kycCheck/KycCheck.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BCardText,
    BTable,
    BForm,
    vSelect,
    BFormInput,
    BButton,
    BTabs,
    BCardText,
    BTab,
    BFormCheckbox,
    BFormTextarea,
    BFormGroup,
    BPagination,
    kycCheckComponent,
  },
  data() {
    return {
      // fields: ["Status", "Date", "Remark"],
      // items: [
      //   {
      //     Status: "Non-active",
      //     Date: "2020-10-13  20：18：23",
      //     Remark: "Fraud",
      //   },
      //   {
      //     Status: "Active",
      //     Date: "2020-10-13  23：34：52",
      //     Remark: "Shaw",
      //   },
      // ],
      // selectStatusOption: ["Active", "Non-active"],
      // operationForm: {
      //   selectStatus: null,
      //   remark: null,
      //   password: null,
      // },
      showStatus: {
        0: 'All',
        1: 'Active',
        2: 'Non-active',
      },
      bordered: true,
      small: true,
      recipient: [],
      userDetail: {},
      bankAccountData: [],
      bankAccountDataFields: [
        { key: 'bankName', label: 'bank name' },
        { key: 'bankCode', label: 'bank code' },
        { key: 'documentType', label: 'document Type' },
        { key: 'documentId_f', label: 'document Id' },
        { key: 'beneficiaryName', label: 'beneficiary Name' },
        { key: 'agency', label: 'bank branch' },
        { key: 'accountNumber', label: 'account Number' },
        { key: 'accountTypeText', label: 'account Type' },
      ],
      bankAccountDataMEXFields: [
        { key: 'bankName', label: 'bank name' },
        { key: 'bankCode', label: 'bank code' },
        { key: 'documentType', label: 'document Type' },
        { key: 'documentId_f', label: 'document Id' },
        { key: 'beneficiaryName', label: 'beneficiary Name' },
        { key: 'accountNumber', label: 'account Number' },
        { key: 'accountTypeText', label: 'account Type' },
      ],
      picAccountData: [],
      picAccountDataFields: [
        { key: 'pixTypeText', label: 'PIX type' },
        { key: 'pixValText', label: 'PIX key' },
      ],
      pixAccountData: [],
      pixAccountDataFields: [
        { key: 'pixTypeText', label: 'PIX type' },
        { key: 'pixVal', label: 'PIX key' },
      ],
      mpAccountData: [],
      mpAccountDataFields: [{ key: 'email', label: 'Email' }],
      userStatusChecked: false,
      userKycData: [],
      kycRecordsData: [],
      recordFields: [
        { key: 'created_at', label: 'Date' },
        { key: 'admin_name', label: 'Admin Name' },
        { key: 'action', label: 'Action' },
        { key: 're_submit_count', label: 'Re-submit count' },
        { key: 'content', label: 'content' },
      ],
      required,
      enterNoteBelow: '',
      currentPage: 1,
      perPage: 10,
      totalRecord: 0,
    }
  },
  computed: {
    createTime() {
      if (this.userDetail.createdAt) {
        return updateDate(this.userDetail.createdAt, getQueryString('timezone'))
      }
      return ''
    },
    documentId() {
      if (this.userDetail.documentId) {
        return formatDocumentId(
          this.userDetail.documentId,
          this.userDetail.currency,
        )
      }
      return ''
    },
    phoneNumber() {
      if (this.userDetail.userName) {
        return formatPhoneNumber(this.userDetail.userName)
      }
      return ''
    },
    documentType() {
      if (this.userDetail.documentId) {
        return getDocumentType(this.userDetail.documentId, this.userDetail)
      }
      return ''
    },
    dataMeta() {
      return {
        from: this.currentPage * this.perPage - (this.perPage - 1),
        to:
          this.totalRecord - this.currentPage * this.perPage > 0
            ? this.currentPage * this.perPage
            : this.totalRecord,
        of: this.totalRecord,
      }
    },
  },
  mounted() {
    this.initUserDetailDetail()
    // this.$refs['kyc-check'].show()
  },
  methods: {
    initUserDetailDetail() {
      this.$showLoading()
      const { id } = this.$route.query
      this.$http
        .get(`/api/v1/user/details?uuid=${id}`)
        .then(response => {
          this.$hideLoading()
          if (response.data.code === 200) {
            const {
              kycRecords, recipient, userDetail, userKyc,
            } = response.data.data
            this.kycRecordsData = kycRecords || []
            this.totalRecord = this.kycRecordsData.length
            this.userDetail = userDetail
            this.userKycData = userKyc || {}
            this.userStatusChecked = userDetail.userStatus === 1
            this.recipient = recipient
            this.filterRecipient(recipient)
            this.$emit('userInfo', {
              username: userDetail.name,
              userid: userDetail.uuid,
            })
          } else {
            this.showToast('warning', response.data.message)
          }
        })
        .catch(error => {
          this.$hideLoading()
          this.showToast('warning', error.message)
        })
    },
    // 筛选数据(1 银行卡 2 picpay 3 pix 4 mercadopago)
    filterRecipient(recipient) {
      if (!recipient) return
      this.bankAccountData = recipient.filter(
        value => value.recipientType == 1,
      )
      this.picAccountData = recipient.filter(
        value => value.recipientType == 2,
      )
      this.pixAccountData = recipient.filter(
        value => value.recipientType == 3,
      )
      this.mpAccountData = recipient.filter(value => value.recipientType == 4)
      this.updateBankAccount()
      this.updatePicAccount()
      this.updatePixAccount()
    },
    // 更新bank数据
    updateBankAccount() {
      this.bankAccountData.forEach(bank => {
        bank.documentId_f = formatDocumentId(
          bank.documentId,
          this.userDetail.currency,
        )
        // 1:checking,  2:saving
        // bank.accountTypeText = bank.accountType == 1 ? 'CHECKING' : 'SAVINGS' // 从后端取 20220915
        if (this.userDetail.isoCode !== 'MEX') {
          bank.accountNumber = formatAccountNumber(bank.accountNumber)
        }
        bank.documentType = getDocumentType(bank.documentId, this.userDetail)
      })
    },
    // 更新PIC数据
    updatePicAccount() {
      this.picAccountData.forEach(pic => {
        // accountType
        // pic.documentId = formatDocumentId(pic.documentId);
        pic.pixValText = formatDocumentId(
          pic.documentId,
          this.userDetail.currency,
        )
        pic.pixTypeText = getDocumentType(pic.documentId, this.userDetail)
      })
    },
    // 更新PIX数据
    updatePixAccount() {
      this.pixAccountData.forEach(pix => {
        // CNPJ 1 CPF 2 账号id 3 phone 4 email 5
        switch (pix.pixType) {
          case 1:
            pix.pixTypeText = 'CNPJ'
            pix.pixVal = formatCNPJ(pix.pixVal)
            break
          case 2:
            pix.pixTypeText = 'CPF'
            pix.pixVal = formatCPF(pix.pixVal)
            break
          case 4:
            pix.pixTypeText = 'Phone number'
            pix.pixVal = formatPhoneNumber(pix.pixVal)
            break
          case 3:
            pix.pixTypeText = 'EVP'
            break
          case 5:
            pix.pixTypeText = 'Email'
            break
          default:
            break
        }
      })
    },
    showToast(variant = null, message = '') {
      this.$bvToast.toast(`${message}`, {
        title: `${variant || 'Error'}`,
        variant,
        solid: true,
      })
    },
    userStatusCheckedChange(value) {
      const that = this
      if (value) {
        // enable
        that
          .$swal({
            title: 'Are you sure you wish to enable this user?',
            text: 'The user may have been disabled for reasons such as fraud or KYC',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Enable',
            customClass: {
              cancelButton: 'btn btn-outline-danger ml-1',
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          .then(result => {
            if (result.value) {
              that.userDetail.userStatus = 1
              that.$showLoading()
              that.changeUserStatus(value, () => {
                that
                  .$swal({
                    icon: 'success',
                    title: 'Enabled!',
                    text: 'Your user status has been Enabled.',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                  .then(() => {
                    window.location.reload()
                  })
              })
            } else if (result.dismiss === 'cancel') {
              that.$nextTick(() => {
                that.userStatusChecked = !value
              })
            }
          })
      } else {
        // disable
        that
          .$swal({
            title: 'Are you sure you wish to disable this user?',
            text: 'The user will be unable to use any wallet functionality.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Disable',
            customClass: {
              cancelButton: 'btn btn-outline-danger ml-1',
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          .then(result => {
            if (result.value) {
              that.userDetail.userStatus = 2
              that.$showLoading()
              that.changeUserStatus(value, () => {
                that
                  .$swal({
                    icon: 'success',
                    title: 'Disabled!',
                    text: 'Your user status has been Disabled.',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                  .then(() => {
                    window.location.reload()
                  })
              })
            } else if (result.dismiss === 'cancel') {
              that.$nextTick(() => {
                that.userStatusChecked = !value
              })
            }
          })
      }
    },
    changeUserStatus(value, callback) {
      let url = ''
      if (value) {
        url = '/api/v2/admin/user_status/enabled'
      } else {
        url = '/api/v2/admin/user_status/disabled'
      }
      this.$http
        .post(url, { user_id: this.userDetail.uuid })
        .then(response => {
          this.$hideLoading()
          if (response.data.code == 200) {
            // eslint-disable-next-line no-unused-expressions
            callback && callback()
          } else {
            this.showToast('warning', response.data.message)
          }
        })
        .catch(error => {
          this.$hideLoading()
          this.showToast('warning', error.message)
        })
    },
    showNoteModal() {
      this.$refs['enter-note-below'].show()
    },
    addNote() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$http
            .post('/api/v2/admin/user_log/save', {
              user_id: this.userDetail.uuid,
              content: this.enterNoteBelow,
            })
            .then(response => {
              this.$hideLoading()
              if (response.data.code == 200) {
                this.$refs['enter-note-below'].hide()
                this.currentPage = 1
                // this.$refs['kyc-records-table'].refresh()
                this.initUserDetailDetail()
              } else {
                this.showToast('warning', response.data.message)
              }
            })
            .catch(error => {
              this.$hideLoading()
              this.showToast('warning', error.message)
            })
          this.$refs['enter-note-below'].hide()
        }
      })
    },
    kycCheckFunc() {
      this.$refs['kyc-check'].show()
    },
    afterKycCheck() {
      this.$refs['kyc-check'].hide()
      window.location.reload()
    },
    formatKycStatustext(value) {
      if (value === '') return ''
      if (!value) return 'No upload'
      switch (value) {
        case 0:
          return 'No upload'
        case 2:
          return 'Permanently blocked'
        case 3:
          return 'Auto-pass'
        case 4:
          return 'Verified'
        case 5:
          return 'Re-upload request'
        default:
          return ''
      }
    },
    formatStatusBg(value) {
      if (value === '') return ''
      switch (value) {
        case 0:
          return '#818181'
        case 2:
          return '#8b595b'
        case 3:
          return '#acb53c'
        case 4:
          return '#52b07c'
        case 5:
          return '#dd5158'
        default:
          return '#818181'
      }
    },
    handleKycCheckOk(bvModalEvt) {
      bvModalEvt.preventDefault()
    },
    enterNoteBelowChange(value) {
      return value.slice(0, 300)
    },
  },
  setup() {
    return { updateDate, getQueryString }
  },
}
</script>
<style lang="scss" scoped>
.base-info {
  .basic-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .basic-content-c {
      width: 38%;
      text-align: left;
    }
    .basic-content-d {
      width: 24%;
      text-align: left;
    }
  }
  ::v-deep .history-table,
  ::v-deep .receiving-table {
    word-break: break-all;
    word-wrap: break-all;
    .table th,
    .table td {
      padding: 10px !important;
    }
  }
  ::v-deep .history-table {
    .table tr td:nth-of-type(1) {
      width: 7.2rem;
    }
    .table tr td:nth-of-type(2) {
      width: 12.7rem;
    }
  }
  .per-page-selector {
    width: 90px;
  }
  .userinfo-form label {
    width: 100px;
    justify-content: left;
  }
  .invoice-filter-select {
    min-width: 280px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  .d-inline-block {
    width: 280px;
  }
  .other-account {
    display: flex;
    justify-content: space-around;
  }
  .kyc-id-wrap {
    width: 80%;
    margin: 0 auto;
    border: 4px solid #7367f0;
    .kyc-id-upload {
      display: flex;
      padding: 20px 0 20px;
      justify-content: space-evenly;
      .kyc-id-box {
        text-align: center;
        font-weight: bold;
        width: 22%;
        height: auto;
      }
    }
    .kyc-id-check {
      width: 100%;
      border-radius: 0;
    }
  }
  .record-note {
    position: absolute;
    top: 10px;
    right: 20px;
  }
}
.img-box {
  width: 150px;
  height: 200px;
  position: relative;
  margin-left: 40px;
  img {
    width: 100%;
    height: 100%;
  }
  .id-card-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 35px;
    // background-color: #52b07c;
    opacity: 0.7;
  }
  .id-card-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 35px;
    line-height: 35px;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
}
</style>
